import React, { useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';


const DatePicker = (
    {
      name,
      label,
      value,
      onDateChange,
      truncate = true,
      dateFormat = 'dd/mm/yy'
    }
  ) => {

    const [date, setDate] = useState(value);
    const [valid, setValid] = useState(!(value === null || value === ''));


    useEffect(() => {
      setDate(value);
    }, [value]);


    const handleDateChange = (e) => {
      setDate(e.value);
      if (e.value === null || e.value === '') {
        setValid(false);
        return;
      }
      setValid(true);
      if (onDateChange) return onDateChange(e);
    };


    return (
    <div className="dws-select-label">
      <div className={`content-label ${truncate ? 'truncate' : ''}`}>
        {label}
      </div>
        <Calendar 
          name={name}
          className={`dws-datepicker dws-content-value ${valid ? "" : "p-invalid"}`}
          value={date}
          onChange={(e) => handleDateChange(e)}
          dateFormat={dateFormat}
          showIcon={true}
          style={{margin: "0px"}}
        ></Calendar>

    </div>
  );
};
  
export { DatePicker };
  