import React from "react";
import { Dropdown } from "primereact/dropdown";

const DWSSelect = ({ options, onChange, value, label, placeholder, id }) => {

  function handleOnChange(e) {
    return onChange(e.value);
  }
  return (
    <Dropdown
      id={id}
      value={value}
      onChange={handleOnChange}
      options={options}
      optionLabel={label}
      placeholder={placeholder}
      className="w-full filter-dropdown"
    />
  );
};
export { DWSSelect };
