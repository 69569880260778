import React, { useEffect, useState, useRef } from "react";
import { MultiSelect } from "primereact/multiselect";

const DWSMultiSelect = ({
  options = [],
  selectedValues = [],
  onChange,
  truncate = true,
  label,
  isMultiple = false,
  name = null,
  itemLabel = "label",
  itemValue = "value",
  minValues = 0
}) => {
  if (options.length === 0) {
    return (
      <div className="dws-select-label">
        <div className={`content-label ${truncate ? "truncate" : ""}`}>
          {label}
        </div>
        <MultiSelect disabled placeholder="No options available" className="dws-multiselect dws-content-value" />
      </div>
    );
  }
  const [selected, setSelected] = useState(selectedValues);
  const [valid, setValid] = useState(selectedValues.length >= minValues);
  
  const handleChange = (e) => {
    setSelected(e.value);
    if (e.value && e.value.length < minValues) {
      setValid(false);
      return;
    }
    setValid(true);

    if (onChange) return onChange(e);
  };

  const template = (option) => {
    return <div className="p-clearfix">{option[itemLabel]}</div>;
  };

  return (
    <div className="dws-select-label">
      <input type="hidden" value={selected} name={name} />
      <div className={`content-label ${truncate ? "truncate" : ""}`}>
        {label}
      </div>
      <MultiSelect
        value={selected}
        options={options}
        onChange={handleChange}
        optionLabel={itemLabel}
        optionValue={itemValue}
        placeholder="Select an option"
        itemTemplate={template}
        selectedItemsLabel="{0}"
        filter
        className={`dws-multiselect dws-content-value ${valid ? "" : "p-invalid"}`}
        display="chip"
        showSelectAll={isMultiple ? true : false}
        selectionLimit={isMultiple ? null : 1}
        showClear={true}
      />
    </div>
  );
};

export { DWSMultiSelect };
