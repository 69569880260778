import React, { useState, useEffect } from 'react';
import { InputTextarea } from "primereact/inputtextarea";

const DWSTextArea = ({ cols, rows, value, onChange, name, emptyAllowed = true, placeholderText = '' }) => {

  const [valid, setValid] = useState(() => emptyAllowed ? true : !(value === null || value === ''));

  function handleChange(e) {

    if (e.target.value === null || e.target.value === '') {
      setValid(!!emptyAllowed);
      return;
    }
    setValid(true);

    if (onChange) return onChange(e.target.value);
  }

  return (
    <InputTextarea
      name={name}
      defaultValue={value}
      onChange={(e) => handleChange(e)}
      rows={rows}
      cols={cols}
      autoResize={false}
      className={`fixed-textarea ${valid ? "" : "p-invalid"}`}
      placeholder={placeholderText}
    />
  );
};

export { DWSTextArea };
