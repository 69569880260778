import React, { useState } from "react";
import { Dropdown, Icon } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";
import { useMeasure } from "@dws/dws-main-page-scaffold";

// Types are not being used as this is using javascript, in case we
// ever want to use typescript, we can uncomment the types and use them

// type TabWrapperProps = {
//   children: React.ReactElement<{
//     title: string;
//     children: React.ReactNode;
//   }>[];
// };

// type TabProps = {
//   title: string;
//   children: React.ReactNode;
// };

// type TabsProps = {
//   children: React.ReactElement<TabProps>[];
//   activeTab: number;
//   setActiveTab: (index: number) => void;
// };

// type TabGenProps = {
//   index: number;
//   className: string;
//   onClick: () => void;
//   child: React.ReactElement<TabProps>;
// };

export function TabWrapper({ children, widgets = [] }) {
  const [activeTab, setActiveTab] = useState(0);
  const [ref, { width }] = useMeasure();
  const isMobile = width < 764;

  return (
    <div ref={ref} className="tab-wrapper">
      <div className="tab-wrapper__tabs">
        <Tabs
          children={children}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMobile={isMobile}
          widgets={widgets}
        />
      </div>
      {React.Children.map(children, (child, index) => {
        if (!child) return null;
        const tab = child.props.children;
        const className = `tab-wrapper__tab-content ${
          activeTab === index ? "active" : ""
        }`;
        return <div className={className}>{tab}</div>;
      })}
    </div>
  );
}

function Tabs({ children, activeTab, setActiveTab, isMobile, widgets }) {
  const { t } = useTranslation();

  const dropdownConfig = {
    constrainWidth: true,
    autoTrigger: true,
    closeOnClick: true,
    alignment: "right",
  };

  function TabGen({ index, className, onClick, child }) {
    const tabCounts = JSON.parse(localStorage.getItem('tabCounts')) || {};
    const count = tabCounts[`table-${child.props.name}`] || 0;
    return (
      <div key={index} className={className} onClick={onClick}>
        {child} ({count})
      </div>
    );
  }

  function generateClassName(activeTab, index, tableName) {
    return `tab-wrapper__tab ${activeTab === index ? "active" : ""} table-${tableName}`;
  }

  const generatedTabs = children.map((child, index) => {
    if (!child) return null;
    if (isMobile && index > 1) return null;
    const tableName = child.props.name;
    const className = generateClassName(activeTab, index, tableName);
    const onClick = () => setActiveTab(() => index);
    return (
      <TabGen
        index={index}
        className={className}
        onClick={onClick}
        child={child}
      />
    );
  });

  if (isMobile) {
    const tabs = [];

    if (widgets) {
      if (widgets.includes("collaborative_routines_list") || widgets.includes("all")) {
        tabs.push({
          index: 2,
          label: t("reports.collaborative_routines"),
        });
      }

      if (widgets.includes("5why_list")  || widgets.includes("all")) {
        tabs.push({ index: 3, label: "5Whys" });
      }
    }

    const dropdownTab = (
      <div className="tab-wrapper__tab reports-dropdown">
        <Dropdown
          options={dropdownConfig}
          trigger={<Icon className="icon">more_horiz</Icon>}>
          {tabs.map(
            (tab) =>
              activeTab !== tab.index && (
                <div
                  key={tab.index}
                  onClick={() => setActiveTab(tab.index)}
                  className="comment-dropdown-option">
                  {tab.label}
                </div>
              )
          )}
        </Dropdown>
      </div>
    );

    generatedTabs.push(dropdownTab);
  }

  return generatedTabs;
}

export function Tab({ title, children }) {
  return <span>{title}</span>;
}
