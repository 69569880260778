import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

const DWSModal = ({
  header = "",
  visible,
  onHide,
  dismissible = true,
  showCloseIcon = true,
  footer = null,
  children,
  position = "center",
  style = { width: "50vw" }
}) => {
  const [isDialogVisible, setIsDialogVisible] = useState(visible);

  useEffect(() => {
    setIsDialogVisible(visible);
  }, [visible]);

  function toggleDialog(e) {
    if (dismissible) {
      setIsDialogVisible(!isDialogVisible);
      if (onHide) return onHide(e);
    }
  }

  return (
    <Dialog
      visible={isDialogVisible}
      style={style}
      header={header}
      footer={footer}
      onHide={toggleDialog}
      closable={showCloseIcon}
      draggable={false}
      position={position}
    >
      {children}
    </Dialog>
  );
};

export { DWSModal };
