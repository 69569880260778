import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const DWSTable = ({ data, columns, onRowClick, ref, onValueChange = () => {} }) => {
  const renderColumnBody = (data, column) => {
    if (column.customComponent)
      return column.customComponent(data[column.key], data);
    return data[column.key];
  };

  function sendRowDataOnRowClick(event) {
    onRowClick?.(event.data);
  }

  return (
    <DataTable
      ref={ref}
      value={data}
      sortMode="multiple"
      onRowClick={sendRowDataOnRowClick}
      onValueChange={onValueChange}
    >
      {columns.map((column) => (
        <Column
          key={column.key}
          field={column.key}
          header={column.title}
          style={column.style}
          body={(data) => renderColumnBody(data, column)}
          sortable={column.sortable !== false}
        />
      ))}
    </DataTable>
  );
};

export { DWSTable };
