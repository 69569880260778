import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';

const DWSInput = ({
    name,
    value,
    onChange,
    truncate = true,
    label,
    emptyAllowed = true
}) => {

    const [valid, setValid] = useState(() => emptyAllowed ? true : !(value === null || value === ''));

    function handleChange(e) {
        if (!emptyAllowed && (e.target.value === null || e.target.value === '')) {
            setValid(false);
            return;
        }
        setValid(true);
        if (onChange) return onChange(e.target.value);
    };

    return (
        <div className="dws-select-label">
            <div className={`content-label ${truncate ? 'truncate' : ''}`}>
                {label}
            </div>
            <InputText 
                defaultValue={value} 
                name={name} 
                onInput={(e) => handleChange(e)} 
                className={`dws-content-value ${valid ? "" : "p-invalid"}`}
            />
        </div>
    );
}

export { DWSInput };