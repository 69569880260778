import React from 'react';

const LabelSelect = (
  {
    label,
    value,
    truncate = true
  }
) => (
  <div className="custom-select-label">
    <div className={`content-label ${truncate ? 'truncate' : ''}`}>
      {label}
    </div>
    <div className={`content-value ${truncate ? 'truncate' : ''}`}>
      {value}
    </div>
  </div>
);

export { LabelSelect };
