import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min.js";
import "./styles.less";
import "./datadog";


const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

export {
  Button,
  Card,
  CardPanel,
  CardTitle,
  Col,
  Collapsible,
  CollapsibleItem,
  Divider,
  Dropdown,
  Icon,
  Modal,
  Navbar,
  Row,
  Select,
  SideNav,
  SideNavItem,
  Table,
  Textarea,
  TextInput,
  Toast,
} from "react-materialize";

export { Loading } from "./components/Loading";
export { TabWrapper, Tab } from "./components/TabWrapper";
export { LabelSelect } from "./components/LabelSelect";
export { DatePicker } from "./components/DatePicker";
export { DWSMultiSelect } from "./components/DWSMultiSelect";
export { DWSSelect } from "./components/DWSSelect";
export { DWSTextArea } from "./components/DWSTextArea";
export { DWSInput } from "./components/DWSInput";
export { DWSTable } from "./components/DWSTable";
export { Button as DWSButton } from "primereact/button";
export { DWSModal } from "./components/DWSModal";
export { AutoComplete  as DWSAutocomplete } from 'primereact/autocomplete';
export { InputText } from 'primereact/inputtext';
export { InputTextarea } from 'primereact/inputtextarea';
export { Toast as DWSToast } from 'primereact/toast';

/* Icons */
export {
  DWSCheckCircleIcon,
  DWSCircleIcon,
  DWSAbReportIcon,
  DWSInfoIcon,
  DWSRibbonIcon,
  DWSStatusCanceledIcon,
  DWSLinkIcon,
  DWSGroupIcon,
} from "./icons/DWSIcons";
